<template>
  <div class="exchange">
    <BgTop></BgTop>
    <div class="exchange-mian">
      <div class="typeList-box">
        <div
          :class="[
            'typeList-item',
            { 'typeList-item-active': typeActive === item }
          ]"
          v-for="item in typeList"
          :key="item"
          @click="typeActive = item"
        >
          {{ item }}
          <span v-if="typeActive === item"></span>
        </div>
      </div>

      <div v-if="typeActive === '现金'" class="card-box">
        <div
          class="card-item"
          @click="goUrl(item)"
          v-for="(item, index) in list"
          :key="index"
        >
          <img :src="item.thumbImageUrl" />
          <span class="name">{{ item.name }}</span>
          <div class="number">{{ item.displayPoints }} <span>金币</span></div>
        </div>
      </div>
      <van-empty v-else description="暂无数据" />
      <Tabbar :tabbarType="1"></Tabbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeList: ["现金", "卡券", "实物"],
      typeActive: "现金",
      list: []
    };
  },
  methods: {
    goUrl(data) {
      this.$router.push(`/mobile/exchangeDetails?id=${data.id}`);
    }
  },
  async created() {
    const res1 = await this.$http.get(`/api/v1/Gifts`, {
      params: {
        page: 1,
        size: 100
      }
    });
    console.log("res1", res1);
    if (res1.status === 200) {
      this.list = res1.data.list;
    }
  }
};
</script>

<style lang="scss" scoped>
.exchange {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: #f5f5f8;

  .exchange-mian {
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 10px 15px 60px 15px;

    .typeList-box {
      display: flex;
      align-items: center;
      height: 40px;
      width: 100%;
      margin-bottom: 16px;
      .typeList-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 42px;
        cursor: pointer;
        span {
          display: block;
          width: 14px;
          height: 4px;
          background: #ffa200;
          border-radius: 10px 10px 10px 10px;
          margin-top: 7px;
        }
      }
      .typeList-item-active {
        color: #ffa200;
        font-size: 22px;
        font-weight: bolder;
      }
    }
    .card-box {
      display: flex;
      flex-wrap: wrap;

      .card-item {
        width: calc((100% - 20px) / 3);
        background: #ffffff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.051);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 10px;
        img {
          width: 60px;
          margin-top: 20px;
        }
        .name {
          color: #232323;
          font-size: 14px;
          margin-top: 21px;
          padding: 0 5px;
          text-align: center;
          overflow: hidden;
          width: 100%;
          box-sizing: border-box;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
        }
        .number {
          margin-top: 9px;
          font-size: 18px;
          color: #ff3636;
          margin-bottom: 16px;
          span {
            font-size: 12px;
          }
        }
      }
      .card-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
